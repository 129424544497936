<template>
    <v-app>
        <div style="height:35px;padding-top:20px;padding-right:20px;background-color:#EEE;">
            <div style="position:absolute;transform:translate(10px, -15px)">
                <v-icon color="#555">mdi-application</v-icon>
                <span style="color: #555">
                    portfolio.exe - Valentin BORDEIANU
                </span>
            </div>
            <v-row style="float:right;">
                <v-icon color="#aae091">mdi-triangle</v-icon>
                <v-icon color="#ffabab">mdi-square</v-icon>
                <v-icon color="#ffd984">mdi-circle</v-icon>
            </v-row>
        </div>

        <div style="padding-top:30px;margin-left:auto;margin-right:auto;text-align:center;">
            <v-card 
                width="80px" 
                height="80px" 
                class="rounded-circle"
                elevation="10"
                style="position:absolute;transform:translate(-90px, 0px)"
            >
                    <v-img 
                        max-width="80px"
                        max-height="80px"
                        src="https://i.ibb.co/ZVtf6CQ/new-photo.png"
                    ></v-img>
            </v-card>

            <h1 style="align-self:center;">
                    Valentin Bordeianu
            </h1>

            <div>
                <v-icon>mdi-email</v-icon> valentin.bordeianu@protonmail.com
            </div>
        </div>

        <br/>
        <br/>
        
        <v-card dark width="495px" height="230px" style="margin-left:auto;margin-right:auto;">
            <div class="ma-2">
                <span style="color:#cda869">valentin</span>
                <span style="color:#a5c261">~$</span>

                <span style="color:#bbb"> {{ console_input }}</span>

                <br />

                <pre width="100%">{{ console_output }}</pre>
            </div>
        </v-card>

        <!--<br/>
        <br/>
        <v-row style="margin-left:auto;margin-right:auto;">
            <v-card v-for="(skill, i) in skills" :key="i"
                class="rounded-circle ma-2"
                elevation="10"
                style="background-color:#262c3a;"
                width="60px" height="60px"
            >
                <v-img
                    width="100%"
                    height="100%"
                    :src="skill.image"
                ></v-img>
            </v-card>
        </v-row>-->

        <br/>

        <h1 style="margin-left:auto;margin-right:auto;">Personal Solo Projects</h1>
        <br/>

        <v-container class="grey lighten-5">
            <v-row no-gutters>
                <v-col
                    v-for="(project, i) in projects"
                    :key="i"
                    cols="auto"
                    :sm="project.collumns"
                    style="padding:2px;"
                >
                    <v-card
                        outlined
                        tile
                        dark
                        elevation="24"
                        @click="open_project(project);"
                    >
                        <v-img :src="project.thumbnail" width="100%" height="200px"
                            :gradient="project.color ? `to top right, ${project.color}, rgba(0, 0, 0 ,0)` : ''"
                        >
                            <v-card-title>{{project.name}}</v-card-title>
                        </v-img>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <br/>
        <br/>

        <v-dialog v-model="dialog" width="60%">
            <v-card v-if="project != null" width="100%" tile elevation="12">
                <v-card-title >
                    {{ project.name }}
                </v-card-title>
                <v-card-subtitle>
                    {{ project.short }}
                </v-card-subtitle>

                <v-carousel
                    v-if="dialog"
                    class="px-5"
                    height="400"
                    hide-delimiter-background
                    show-arrows-on-hover
                >
                    <v-carousel-item
                        v-for="(slide, i) in project.slides"
                        :key="i"
                    >
                        <v-sheet height="100%" >
                            <v-row
                                class="fill-height"
                                justify="center"
                            >
                                <iframe v-if="slide.includes('www.youtube.com')"
                                    width="100%" style="height: calc(100% - 40px)" 
                                    :src="slide"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media;"
                                    allowfullscreen>
                                </iframe>
                                <v-img v-else :src="slide" width="100%" height="100%"/>
                            </v-row>
                        </v-sheet>
                    </v-carousel-item>
                </v-carousel>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<style>

</style>

<script>

export default {
    name: 'App',

    components: {
    },

    mounted() {
        this.project = this.projects[0];
        this.console_simulator("./startup.sh",
                "================    Listing Skills    ================\n" +
                "  Gamedev............... ✓  Ok Web Dev............✓\n" +
                "  Mobile Applications... ✓  Shaders...............✓\n" +
                "  Networking............ ✓  Compute Buffers.......✓\n" +
                "  Optimizations......... ✓  Threading.............✓\n" +
                "  Efficient............. ✓  Bug Solving...........✓\n" +
                "================ All systems online ✓ ================");
    },

    data: () => ({
        dialog: false,
        project: null,
        console_input: "",
        console_output: "",
        skills: [
            {
                name: "Unity 3D",
                image: "https://unity3d.com/profiles/unity3d/themes/unity/images/pages/branding_trademarks/unity-tab-square-black.png"
            },
            {
                name: "C#",
                image: "https://fiverr-res.cloudinary.com/images/q_auto,f_auto/gigs/131633729/original/7dd1af11d0c947b49b002eee795f24b3403cc0e6/code-in-c-sharp-for-your-project.png"
            },
            {
                name: "C++",
                image: "https://i.redd.it/31b2ii8hchi31.jpg"
            },
            {
                name: "C",
                image: "https://cdn.iconscout.com/icon/free/png-512/c-programming-569564.png"
            },
            {
                name: ".NET",
                image: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a3/.NET_Logo.svg/1200px-.NET_Logo.svg.png"
            }
        ],

        projects: [
            {
                name: "Blencraft",
                short: "Voxel Engine Inspired by Minecraft",
                slides: [
                    "https://www.youtube.com/embed/Jcqen8CPa14",
                    "https://www.youtube.com/embed/SvUm-UMofEc",
                    "https://www.youtube.com/embed/ZxR_UdB-vIk",
                    "https://www.youtube.com/embed/f0iWS5Ijfjo",
                    "https://i.redd.it/80y85hzwynx41.png",
                    "https://external-preview.redd.it/klUUvS8ITY8XQqYCzfhOcHyYGPLzffk-s0IMLOCxGoI.png?auto=webp&s=4eb32f2b406a6be4c4f5b42ca4fe36cf961618fa",
                    "https://external-preview.redd.it/NrrJr9OF82SYlKxZvtN-S_PIX8EH0sEfN-SXUKJ1Ecg.jpg?auto=webp&s=0172ea3ca1d19fafee0e40ad1d5433711ab7a36a",
                    "https://external-preview.redd.it/izkcRWgQQ4IPw7hagpDllb8owOkcjlMgTIAglis4_30.png?auto=webp&s=cddc7f17b69538775f76e31a05bc65daa3fc5aa6",
                ],
                thumbnail: "https://media.giphy.com/media/EQgsUgOPc3lfvtB8W3/giphy.gif",
                collumns: 8,
                color: "#0026ff50"
            },
            {
                name: "Vandalise To The Rythm",
                short: "Quick Project For Fun That Plays To The Music's Rythm",
                slides: [
                    "https://www.youtube.com/embed/SWutv7RFH8Y",
                ],
                thumbnail: "https://media.giphy.com/media/ueZPFD2lV98itP2Xz8/source.gif",
                collumns: 4,
                color: "#50000078"
            },
            {
                name: "Bug Wars",
                short: "Chess Like Game But With Dragons And Spells",
                slides: [
                    "https://www.youtube.com/embed/olcP4WwLGrg",
                    "https://www.youtube.com/embed/toe_TSX1law",
                ],
                thumbnail: "https://media.giphy.com/media/uxVLniGKb0QoGSIBsZ/giphy.gif",
                collumns: 4,
                color: "#50290078"
            },
            {
                name: "AI Project - CrossFire",
                short: "External AI That Plays The Game Using Mouse & Keyboard Inputs",
                slides: [
                    "https://www.youtube.com/embed/oHdrI_9wyjY",
                ],
                thumbnail: "https://media.giphy.com/media/BjlUaMsIgcs59CWdXX/giphy.gif",
                collumns: 4,
                color: "#ffe0002e"
            },
            {
                name: "Home Cinema",
                short: "Contrained To The Console Environment, I Made A Home Cinema!",
                slides: [
                    "https://www.youtube.com/embed/6IN5pdUqNOI",
                ],
                thumbnail: "https://media.giphy.com/media/ApfjktfaHoyvPy5BCO/giphy.gif",
                collumns: 4,
                color: "#50290078"
            },
            {
                name: "Bullet Diary",
                short: "Mobile Application To Organize Your Life.",
                slides: [
                    "https://www.youtube.com/embed/99B_O-kD3iU",
                ],
                thumbnail: "https://media.giphy.com/media/RGGOTXGNxWfnGWMdl9/giphy.gif",
                collumns: 2,
                color: "#50290078"
            },
            {
                name: "Procedural Hardcore Parkour",
                short: "Prototype Of A Procedural Parkour System.",
                slides: [
                    "https://www.youtube.com/embed/24b5LuLXqiQ",
                    "https://www.youtube.com/embed/bEg1abaieTY",
                ],
                thumbnail: "https://media.giphy.com/media/frDfwx2Q7wPBLdHaBj/giphy.gif",
                collumns: 4,
                color: "#50290078"
            },
            {
                name: "Hyper-Casual Game",
                short: "Hyper-Casual Game Prototype",
                slides: [
                    "https://www.youtube.com/embed/k5iA6I3khRo",
                ],
                thumbnail: "https://media.giphy.com/media/xYC3SRcVwOiPvIkme0/giphy.gif",
                collumns: 2,
                color: "#50290078"
            },
            {
                name: "Agario Movement Prototype",
                short: "Trying To Replicate The Movement & Splitting Of The Famouse Agar.io Game",
                slides: [
                    "https://www.youtube.com/embed/4Siky6oWBHw",
                ],
                thumbnail: "https://media.giphy.com/media/pcpuXatwTH0DkN7wfy/source.gif",
                collumns: 4,
                color: "#50290078"
            },
            {
                name: "Unity3D Import Stick Figures",
                short: "Pivot Stick Figures Animator Importer",
                slides: [
                    "https://www.youtube.com/embed/KDJot86h0RQ",
                ],
                thumbnail: "https://media.giphy.com/media/1C7GQzMvku3FhiVfzh/source.gif",
                collumns: 4,
                color: "#2e500057"
            },
            {
                name: "Lithtech .DAT & .LTB Importer",
                short: "Lithtech is an old game engine, this tool imports maps & models from it! This map you see is from CrossFire.",
                slides: [
                    "https://external-preview.redd.it/gTZsr0D5VuaAMTmEpCYVWBrVlSWjPuPlokiLJe13qw4.png?width=960&crop=smart&auto=webp&s=36c1be0b5bcceed910df78c41a2ecf27d6a48b13"
                ],
                thumbnail: "https://media.giphy.com/media/zt0VJO9Ntq6hrdtAH3/source.gif",
                collumns: 8,
                color: "#4000502e"
            },
            {
                name: "Breadboard Prototype",
                short: "Prototype of a Breadboard system, where you can place cables & microcontrollers.",
                slides: [
                    "https://www.youtube.com/embed/llt_5SpIGOE",
                ],
                thumbnail: "https://media.giphy.com/media/yHsegkTVSSfe2uc9M8/source.gif",
                collumns: 5,
                color: "#50290078"
            },
            {
                name: "Procedural Island Generator",
                short: "Generates Procedural Islands From One Button Click & One Radius Parameter.",
                slides: [
                    "https://i.ibb.co/XCX0wWH/6a9ql8wvhxb31.png",
                ],
                thumbnail: "https://i.ibb.co/XCX0wWH/6a9ql8wvhxb31.png",
                collumns: 7,
                color: "#50290078"
            },
        ]
    }),

    methods: {
        open_project(proj){
            this.dialog = true;
            this.project = proj;
        },

        console_simulator(command, output) {
            const that = this;

            that.console_input = "";
            that.console_output = "";

            let index = 0;

            const title_func = () => {
                if (index < command.length) {
                    that.console_input += command[index++];
                    setTimeout(title_func, 50);
                } else {
                    index = 0;
                    setTimeout(body_func, 500);
                }
            };

            const body_func = () => {
                if (index < output.length) {
                    that.console_output += output[index++];
                    setTimeout(body_func, 5);
                }
            };

            setTimeout(title_func, 1000);
        }
    }
};
</script>
